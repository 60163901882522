import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import quote from 'img/ui/quote.svg';
import arrowIcon from 'img/ui/arrow_black.svg';

export const Post = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
  }
  h3 {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h4 {
    color: rgb(119, 119, 119);
    font-weight: 700;
    font-size: 1rem;
  }
  a {
    display: inline;
    font-size: 20px;
  }
  p {
    margin-bottom: 1.25rem;
    line-height: 1.8;
    font-size: 20px;
  }
  img {
    max-width: 100%;
    height: auto;
    margin: 1.2rem auto 1.4rem;
    display: block;
  }
  pre,
  p > em > code,
  p code {
    color: #c5c8c6;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: 'Inconsolata', 'Monaco', 'Consolas', 'Courier New', Courier, monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    background: #1d1f21;
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border-radius: 0.3em;
  }
  p > em > code,
  p > code {
    padding: 0.1em;
    border-radius: 0.3em;
    font-size: 78.5%;
    margin: 0;
  }
  figure {
    margin: 0 auto 1.5rem;
    img {
      margin: 0 auto 1rem;
      display: block;
      max-width: 100%;
      height: auto;
      padding-bottom: 0;
    }
    figcaption {
      color: #888;
      width: 85%;
      margin: 0 auto;
      text-align: center;
    }
  }
  p img,
  img {
    margin: 1rem auto 2rem;
    display: block;
    max-width: 100%;
    height: auto;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.16));
  }
  blockquote {
    margin: 3.5rem 0 2rem;
    position: relative;
    background-color: #f1f5f9;
    padding: 48px;
    & p strong a {
      font-weight: 700;
    }
    p:last-child {
      margin-bottom: 0;
    }
    ${media.mobile} {
      padding: 36px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-30%, -50%);
      background-image: url(${quote});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 82px;
      height: 61px;
      ${media.mobile} {
        width: 74px;
        height: 74px;
        transform: translate(0, -50%);
      }
    }
  }
  ul li,
  ol li {
    margin-bottom: 0.5em;
    line-height: 1.8;
    font-size: 20px;
  }
  b {
    font-weight: 700;
  }
  aside {
    padding: 15px 25px;
    border: 2px solid #000;
    margin: 1.5rem 0;
    &.red {
      border-color: #e05c5e;
      color: #e05c5e;
      background-color: #ffebeb;
    }
    h2,
    h3,
    h4 {
      margin-top: 1.25rem;
    }
    ul {
      padding: 0 20px;
      li {
        margin-bottom: 1rem;
      }
    }
    @media (min-width: 768px) {
      padding: 15px 25px;
    }
  }
  aside.blue {
    background: #7cffef;
    border-radius: 4px;
    padding: 24px 48px;
    margin: 4rem 0px;
    border: none;
    h2 {
      font-size: 36px;
      font-weight: 800;
    }
  }
  a.link-button {
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    padding: 12px 36px;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    min-width: 150px;
    font-size: 14px;
    text-align: center;
    background-color: #0446ca;
    margin: 0 auto 4rem;
    display: block;
    width: 250px;
    text-decoration: none;
    &:hover {
      transform: scale(1.06);
    }
  }
  .gatsby-resp-image-wrapper {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  picture {
    img {
      margin: 5rem auto;
      display: block;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.16));
      @media (max-width: 800px) {
        margin: 3rem auto;
      }
    }
  }
  .smaller-video {
    max-width: 60%;
    display: block;
    margin: 0 auto;
    ${media.tablet} {
      max-width: 100%;
    }
  }
  ol {
    ul {
      li {
        margin-bottom: 5px;
      }
    }
  }
  .in-border {
    border: 2px solid black;
  }
  .action-btn {
    color: #fff !important;
    margin: 3rem auto;
    background-color: #0a4ed6;
    padding: 8px 28px;
    border: 2px solid #0a4ed6;
    border-radius: 4px;
    transition: 0.2s ease;
    max-height: 48px;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    cursor: pointer;
    min-width: 140px;
    max-width: fit-content;
    outline: 0;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      color: #0a4ed6 !important;
      background-color: #fff;
      outline: 0;
      border: 2px solid #0a4ed6;
      text-decoration: none;
    }
  }
  .action-banner {
    background-color: #7cffef;
    padding: 16px 24px;
    font-size: 18px;
    color: #000;
    width: 100%;
    display: block;
    margin: 1.5rem 0;
    font-weight: 700;
    border-radius: 4px;
    &::after {
      content: url(${arrowIcon});
      display: inline-block;
      clear: both;
      margin-left: 16px;
      transition: 0.2s ease;
    }
    &:hover {
      text-decoration: underline;
      &::after {
        transform: translateX(8px);
      }
    }
  }
`;
